const EmptyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="178" height="191" viewBox="0 0 178 191" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_dd_14881_188456)">
      <g clipPath="url(#clip0_14881_188456)">
        <rect
          x="55.6182"
          y="1.96289"
          width="116.42"
          height="145"
          transform="rotate(5 55.6182 1.96289)"
          fill="#F5F5F5"
        />
        <g opacity="0.5">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M106.327 87.1016C106.303 87.3767 106.506 87.6192 106.781 87.6433C107.056 87.6674 107.299 87.4639 107.323 87.1888L108.048 78.9052L116.332 79.63C116.607 79.654 116.849 79.4505 116.874 79.1754C116.898 78.9003 116.694 78.6578 116.419 78.6338L108.135 77.909L108.86 69.6245C108.884 69.3494 108.68 69.1069 108.405 69.0828C108.13 69.0587 107.888 69.2622 107.864 69.5373L107.139 77.8218L98.8547 77.0971C98.5796 77.073 98.3371 77.2765 98.313 77.5516C98.289 77.8267 98.4925 78.0692 98.7676 78.0933L107.052 78.818L106.327 87.1016Z"
            fill="black"
          />
        </g>
      </g>
      <rect
        x="56.4946"
        y="2.54168"
        width="115"
        height="144"
        rx="9.5"
        transform="rotate(5 56.4946 2.54168)"
        stroke="black"
        strokeOpacity="0.1"
        strokeDasharray="10 10"
      />
    </g>
    <g filter="url(#filter1_dd_14881_188456)">
      <g clipPath="url(#clip1_14881_188456)">
        <rect
          x="5.98438"
          y="36.0818"
          width="116.42"
          height="145"
          transform="rotate(-5 5.98438 36.0818)"
          fill="#F5F5F5"
        />
        <g opacity="0.5">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M70.7042 111.122C70.7282 111.397 70.9708 111.6 71.2459 111.576C71.5209 111.552 71.7244 111.31 71.7004 111.035L70.9757 102.751L79.2597 102.026C79.5348 102.002 79.7383 101.76 79.7143 101.485C79.6902 101.21 79.4477 101.006 79.1726 101.03L70.8885 101.755L70.1637 93.4704C70.1396 93.1953 69.8971 92.9918 69.622 93.0158C69.3469 93.0399 69.1434 93.2824 69.1675 93.5575L69.8923 101.842L61.6083 102.567C61.3332 102.591 61.1297 102.833 61.1538 103.108C61.1778 103.384 61.4203 103.587 61.6954 103.563L69.9795 102.838L70.7042 111.122Z"
            fill="black"
          />
        </g>
      </g>
      <rect
        x="6.94402"
        y="36.4992"
        width="115"
        height="144"
        rx="9.5"
        transform="rotate(-5 6.94402 36.4992)"
        stroke="black"
        strokeOpacity="0.1"
        strokeDasharray="10 10"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_14881_188456"
        x="37.4023"
        y="0"
        width="140.196"
        height="166.558"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_14881_188456" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14881_188456" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect2_dropShadow_14881_188456" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_14881_188456" result="effect2_dropShadow_14881_188456" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_14881_188456" result="shape" />
      </filter>
      <filter
        id="filter1_dd_14881_188456"
        x="0.402344"
        y="23.9346"
        width="140.196"
        height="166.558"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_14881_188456" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14881_188456" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect2_dropShadow_14881_188456" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_14881_188456" result="effect2_dropShadow_14881_188456" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_14881_188456" result="shape" />
      </filter>
      <clipPath id="clip0_14881_188456">
        <rect x="56.04" y="2" width="116" height="145" rx="10" transform="rotate(5 56.04 2)" fill="white" />
      </clipPath>
      <clipPath id="clip1_14881_188456">
        <rect
          x="6.40234"
          y="36.0447"
          width="116"
          height="145"
          rx="10"
          transform="rotate(-5 6.40234 36.0447)"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
)

export default EmptyIcon
