import Image, { ImageProps } from 'next/image'
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import generateBase64Image from 'shared/utils/generateBase64Image'

export interface CustomImageProps extends Omit<ImageProps, 'src' | 'alt'> {
  fallbackSrc?: string
  src?: string | null
  alt?: string
}

const DEFAULT_FALLBACK_IMAGE_URL = '/images/image-not-found.png'
const DEFAULT_BLUR_IMAGE_URL = '/images/placeholder.webp'

const CustomImage = (props: CustomImageProps) => {
  const { fallbackSrc, placeholder, blurDataURL, fill, width, height, src, alt = 'image alt', ...rest } = props

  const validFallbackSrc = fallbackSrc || DEFAULT_FALLBACK_IMAGE_URL

  const [imageScr, setImageSrc] = useState(src || validFallbackSrc)
  const [placeholderSrc, setPlaceholderSrc] = useState<string | undefined>(blurDataURL)
  const hasFetchedPlaceholder = useRef(false)

  const onError = useCallback(
    (e: SyntheticEvent<HTMLImageElement>) => {
      e.currentTarget.onerror = null // prevents looping
      setImageSrc(validFallbackSrc)
    },
    [validFallbackSrc],
  )

  useEffect(() => {
    setImageSrc(src || validFallbackSrc)
  }, [src, validFallbackSrc])

  useEffect(() => {
    if (hasFetchedPlaceholder.current) return
    if (placeholder !== 'blur') return

    const imageSrc = blurDataURL || src || DEFAULT_BLUR_IMAGE_URL

    // Check if the image is a local image
    if (!imageSrc.startsWith('http')) {
      setPlaceholderSrc(imageSrc)
      return
    }

    // If the image is a remote image, fetch the image and get the base64 placeholder
    async function getPlaceholderImage() {
      const base64 = await generateBase64Image(imageSrc)
      if (base64) {
        setPlaceholderSrc(base64)
        hasFetchedPlaceholder.current = true
      }
    }
    getPlaceholderImage()
  }, [blurDataURL, src, placeholder])

  const otherProps = {
    ...rest,
    placeholder,
    src: imageScr,
    ...(fill ? { fill: true } : { width, height }),
    ...(placeholder === 'blur' && placeholderSrc && { blurDataURL: placeholderSrc }),
  }
  return <Image {...otherProps} onError={onError} alt={alt} />
}

export default CustomImage
