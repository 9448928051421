import throttle from 'lodash.throttle'
import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * Custom hook for handling horizontal scrolling.
 *
 * @param numberOfViews - The number of views to scroll by. Default is 0.8.
 * @returns An object containing the following properties:
 *   - toMostLeft: A boolean indicating if the scroll is at the most left position.
 *   - toMostRight: A boolean indicating if the scroll is at the most right position.
 *   - scrollLeft: A function to scroll left.
 *   - scrollRight: A function to scroll right.
 *   - wrapperRef: A ref to the wrapper element.
 *   - noNeedToScroll: A boolean indicating if the content is smaller than the parent container.
 */
const useScrollLeftRight = (numberOfViews = 0.8) => {
  const [toMostLeft, setToMostLeft] = useState(true)
  const [toMostRight, setToMostRight] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const scrollRight = useCallback(() => {
    const scrollElement = wrapperRef.current
    if (!scrollElement) return
    scrollElement.scrollTo({
      top: scrollElement.scrollTop,
      left: scrollElement.scrollLeft + numberOfViews * scrollElement.clientWidth,
      behavior: 'smooth',
    })
  }, [numberOfViews])

  const scrollLeft = useCallback(() => {
    const scrollElement = wrapperRef.current
    if (!scrollElement) return
    scrollElement.scrollTo({
      top: scrollElement.scrollTop,
      left: scrollElement.scrollLeft - numberOfViews * scrollElement.clientWidth,
      behavior: 'smooth',
    })
  }, [numberOfViews])

  // Side effects
  useEffect(() => {
    const scrollElement = wrapperRef?.current
    if (!scrollElement) return
    const onScroll = throttle(() => {
      const { scrollLeft, clientWidth, scrollWidth } = scrollElement
      const reachedMostLeft = scrollLeft === 0
      const reachedMostRight = scrollLeft + clientWidth === scrollWidth
      setToMostLeft(reachedMostLeft)
      setToMostRight(reachedMostRight)
    }, 300)

    scrollElement.addEventListener('scroll', onScroll)
    return () => scrollElement.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    // Hide the scroll button when the content is smaller than the parent container
    const scrollElement = wrapperRef?.current
    if (!scrollElement) return
    const { scrollWidth, clientWidth } = scrollElement
    setToMostLeft(scrollWidth <= clientWidth)
    setToMostRight(scrollWidth <= clientWidth)
  }, [])

  return {
    toMostLeft,
    toMostRight,
    scrollLeft,
    scrollRight,
    wrapperRef,
    noNeedToScroll: toMostLeft && toMostRight,
  }
}

export default useScrollLeftRight
