const MagnifyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <span data-nosnippet>
    <svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.6234 24.5901C19.162 24.5901 22.8413 20.9108 22.8413 16.3722C22.8413 11.8336 19.162 8.1543 14.6234 8.1543C10.0848 8.1543 6.40552 11.8336 6.40552 16.3722C6.40552 20.9108 10.0848 24.5901 14.6234 24.5901Z"
        stroke="currentColor"
        strokeWidth="1.27186"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.896 26.6423L20.4275 22.1738"
        stroke="currentColor"
        strokeWidth="1.27186"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
)

export default MagnifyIcon
