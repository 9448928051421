import { memo, useCallback } from 'react'
import ScrollWrapper from 'shared/components/layout/ScrollWrapper'
import { useLocalStorage } from 'usehooks-ts'
import RecentSearchItem from './RecentSearchItem'

type RecentSearchesProps = {
  onSelectRecentSearch: (searchText: string) => void
}

const RecentSearches = (props: RecentSearchesProps) => {
  const { onSelectRecentSearch } = props
  const [recentSearches, setRecentSearches] = useLocalStorage<Array<string>>('recentSearches', [])

  const handleRemoveRecentSearchItem = useCallback(
    (index: number) => () => {
      recentSearches.splice(index, 1)
      setRecentSearches(recentSearches)
    },
    [recentSearches, setRecentSearches],
  )

  if (!recentSearches || recentSearches.length === 0) return null

  return (
    <div className="relative z-40 space-y-2">
      <p className="text-xs uppercase text-black/60">RECENT SEARCHES</p>
      <ScrollWrapper containerClassName="hidden lg:flex justify-center" wrapperClassName="full-width-scroll-wrapper">
        <div className="flex gap-1 pb-4">
          {recentSearches.map((item, index) => (
            <RecentSearchItem
              key={`item-${item}`}
              title={item}
              onRemove={handleRemoveRecentSearchItem(index)}
              onSelect={onSelectRecentSearch}
            />
          ))}
        </div>
      </ScrollWrapper>

      <div className="flex flex-wrap gap-1 pb-4 lg:hidden">
        {recentSearches.map((item, index) => (
          <RecentSearchItem
            key={`item-${item}`}
            title={item}
            onRemove={handleRemoveRecentSearchItem(index)}
            onSelect={onSelectRecentSearch}
          />
        ))}
      </div>
    </div>
  )
}

export default memo(RecentSearches)
