import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import { ReactNode, forwardRef, ForwardedRef, useImperativeHandle, useRef } from 'react'
import useScrollLeftRight from 'shared/hooks/useScrollLeftRight'
import SimpleBar, { Props as SimpleBarProps } from 'simplebar-react'

type ScrollWrapperProps = Pick<
  SimpleBarProps,
  'autoHide' | 'scrollbarMinSize' | 'scrollbarMaxSize' | 'forceVisible' | 'clickOnTrack' | 'scrollableNodeProps'
> & {
  children: ReactNode
  wrapperClassName?: string
  containerClassName?: string
  showHorizontalScrollButtons?: boolean
  scrollLeftButtonClassName?: string
  scrollRightButtonClassName?: string
  hideScrollbar?: boolean
}

export type ScrollWrapperRef = {
  recalculate: () => void
}

const DefaultButtonClassNames = {
  right: cn(
    'absolute right-4 top-1/2 z-10 flex h-[35px] w-[35px] -translate-y-1/2 items-center justify-center md:right-16',
    'rounded-full bg-[#F1F1F1] shadow-lg disabled:opacity-40',
    'hidden lg:flex',
  ),
  left: cn(
    'absolute left-4 top-1/2 z-10 flex h-[35px] w-[35px] -translate-y-1/2 items-center justify-center md:left-16',
    'rounded-full bg-[#F1F1F1] shadow-lg disabled:opacity-40',
    'hidden lg:flex',
  ),
}

const ScrollWrapper = (props: ScrollWrapperProps, ref: ForwardedRef<ScrollWrapperRef>) => {
  const {
    children,
    wrapperClassName,
    containerClassName,
    showHorizontalScrollButtons = false,
    scrollLeftButtonClassName = DefaultButtonClassNames.left,
    scrollRightButtonClassName = DefaultButtonClassNames.right,
    hideScrollbar = false,
    ...otherProps
  } = props
  const simpleBarRef = useRef(null)
  const { toMostLeft, toMostRight, scrollLeft, scrollRight, wrapperRef, noNeedToScroll } = useScrollLeftRight()

  useImperativeHandle(ref, () => ({
    recalculate: () => {
      const simplebar = (simpleBarRef.current || {}) as unknown as { recalculate: () => void }
      simplebar.recalculate()
    },
  }))

  return (
    <div className={cn('relative h-full w-full', containerClassName)}>
      {showHorizontalScrollButtons && !noNeedToScroll && (
        <>
          <button className={scrollRightButtonClassName} disabled={toMostRight} onClick={scrollRight}>
            <ChevronRightIcon className="h-5 w-5" />
          </button>

          <button className={scrollLeftButtonClassName} disabled={toMostLeft} onClick={scrollLeft}>
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
        </>
      )}
      <div
        className={cn(
          wrapperClassName,
          'scroll-wrapper box-content w-full overflow-hidden scroll-smooth',
          hideScrollbar && '[&_.simplebar-track]:opacity-0',
        )}
      >
        <SimpleBar
          {...otherProps}
          ref={simpleBarRef}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          scrollableNodeProps={{ ref: wrapperRef }}
        >
          {children}
        </SimpleBar>
      </div>
    </div>
  )
}

export default forwardRef(ScrollWrapper)
