import classNames from 'classnames'
import SpinnerIcon from 'shared/icons/SpinnerIcon'
import { useCallback, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { notifyError } from 'utils/toast'
import EmptyIcon from '../../../icons/EmptyIcon'
import { resizeImageFile } from 'utils/imageHelpers'
import HeaderFindArtBanner from './HeaderFindArtBanner'

type Prop = { onChange: (file: File | null) => void }

const SearchByImage = ({ onChange }: Prop) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Image dropzone handler
  const handleAddImages = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length > 1) {
        notifyError('Maximum 1 image allowed')
        return
      }

      if (fileRejections.length > 0) {
        let errorMessage = ''
        fileRejections.forEach(({ errors }) => {
          errorMessage = errors
            .map((error) => {
              if (error.code === 'file-invalid-type') {
                return 'Can’t upload image. Use an image in one of these formats: GIF, PNG, JPG, JPEG, HEIC'
              }
              if (error.code === 'file-too-large') {
                return 'The file is too large. Allowed maximum size is 2 MB.'
              }
              return error.message
            })
            .join('\n\n')
        })
        notifyError(errorMessage)
        return
      }

      try {
        setIsLoading(true)
        const file = acceptedFiles[0]
        let uploadedFile

        // convert file to jpg if it's heic
        const { convertHeicToJpg, getFileExtension } = await import('utils/imageFile') // prevent issue loading this library server-side
        if (getFileExtension(file.name) === 'heic') {
          const fileData = new Blob([file])
          const convertedFile = await convertHeicToJpg(fileData)
          uploadedFile = convertedFile
        } else {
          uploadedFile = file
        }

        if (uploadedFile) {
          // resize the image since the converting to base64 will increase the size
          uploadedFile = await resizeImageFile(uploadedFile, { maxSize: 800 }) // max 800px
          // convert image file to base64, then upload
          onChange(uploadedFile)
        }
      } catch (e) {
        notifyError(e)
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, onChange],
  )

  // Dropzone

  const {
    getRootProps,
    getInputProps,
    open: openImageSelectionModal,
    isDragActive,
  } = useDropzone({
    onDrop: handleAddImages,
    noClick: true,
    accept: { 'image/*': ['.gif', '.png', '.jpg', '.jpeg', '.heic'] },
    multiple: false,
    maxSize: 1024 * 1024 * 2, // 2MB
  })

  return (
    <>
      <div className="items-center justify-center px-4 py-5">
        <div
          className={classNames(
            'h-full min-h-[200px] w-full rounded-[24px] border-2 border-dashed border-black/10',
            'bg-white font-inter text-kokushoku-black shadow-sm',
          )}
        >
          {/* input */}
          <input className="invisible fixed left-[-9999px] top-0" {...getInputProps()} />
          {/* empty state */}

          <div
            className={classNames(
              'h-full w-full',
              'flex h-full w-full cursor-default items-center justify-center rounded-[24px]',
              (isDragActive || isLoading) && 'bg-primary/5',
            )}
            {...getRootProps()}
          >
            {isLoading && (
              <span className="flex h-full w-full flex-col items-center justify-center gap-4 text-kokushoku-black/30">
                <SpinnerIcon />
                <p className="mt-3 normal-case">Uploading image</p>
              </span>
            )}

            {!isLoading && !isDragActive && (
              <div className="inline-flex  h-full min-h-[200px] w-full flex-col items-center justify-center p-3">
                <EmptyIcon className="h-[130px] w-[120px]" />
                <p className="mt-3 text-center text-sm normal-case lg:text-base">
                  <span className="hidden lg:inline">
                    Drag an image here or&nbsp; <br />
                  </span>
                  <button onClick={openImageSelectionModal} className="text-primary">
                    Upload an image
                  </button>
                </p>
                <p className="mt-4 flex flex-1 items-end text-center text-[10px] normal-case text-[#898788]">
                  Supported formats: GIF, PNG, JPG, JPEG, HEIC. Max size: 2MB
                </p>
              </div>
            )}

            {!isLoading && isDragActive && (
              <div className="inline-flex h-full min-h-[200px] w-full flex-col items-center justify-center text-kokushoku-black/30">
                <p className="mt-3 normal-case">Drop an image here</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* find-art banner */}
      <div className="pt-2">
        <HeaderFindArtBanner />
      </div>
    </>
  )
}

export default SearchByImage
