// TypeScript function to convert a remote image to Base64
async function generateBase64Image(imageUrl: string): Promise<string | undefined> {
  try {
    // Fetch the image as a blob
    const response = await fetch(imageUrl)

    // Check if the response is ok
    if (!response.ok) {
      throw new Error(`Failed to fetch image. Status: ${response.status}`)
    }

    // Convert the response to a Blob
    const blob: Blob = await response.blob()

    // Create a new FileReader instance
    const reader = new FileReader()

    // Return a promise that resolves when the FileReader has read the blob as a data URL
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string) // Type assertion since reader.result can be string or ArrayBuffer
        } else {
          reject('Failed to convert image to Base64.')
        }
      }

      reader.onerror = () => reject('Error occurred while reading the image.')

      // Read the blob as a data URL (Base64)
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    console.log('Error converting image to Base64:', error)
    return undefined
  }
}

export default generateBase64Image
