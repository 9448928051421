const convertPathToPageName = (path: string) => {
  if (!path) return 'homepage'
  return (
    path
      .replace(/\/u\//g, '/users/') // Replace /u with  user
      .split('/')
      .join(' ')
      .replace(/-\w/g, (match) => match.replace('-', ' ')) // Replace hyphens with spaces
      .replace(/\[\w+\]/g, 'details') // Replace parameterized parts like [viewId] with "details"
      .replace(/([A-Z])/g, ' $1') // Insert space before uppercase letters
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Handle camelCase by adding space
      .trim() || 'homepage'
  )
}

export default convertPathToPageName
