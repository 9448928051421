import { useCallback } from 'react'
import useSWR from 'swr'
import { apiClient } from 'utils/apiClient'
import { withApiErrorHandled } from 'utils/common'

type SmartSuggestion = {
  suggestions: Array<{
    query: string
    artworks: Array<{ imageMedium: string }>
  }>
}

const useSmartSuggestion = () => {
  const swrKey = '/search/discovery/public/smart-suggestions'

  const fetcher = useCallback(async () => {
    const response = await withApiErrorHandled<SmartSuggestion>(apiClient.get)(swrKey)
    const { data } = response.data

    return data
  }, [swrKey])

  const swrResponse = useSWR(swrKey, fetcher, { revalidateOnFocus: false })
  return swrResponse
}

export default useSmartSuggestion
