import throttle from 'lodash.throttle'
import { useEffect, useRef, useState } from 'react'

// Use to detect scroll direction for the the whole DOM window
const useScrollDirectionForWindow = () => {
  const [scrollDirection, setScrollDirection] = useState<'down' | 'up'>()
  const prevScrollYRef = useRef(0)

  const updateScrollDirectionRef = useRef(
    throttle(() => {
      const scrollY = window.scrollY
      const prevScrollY = prevScrollYRef.current
      const newDirection = scrollY > prevScrollY ? 'down' : 'up'
      setScrollDirection((currentDirrection) => {
        if (currentDirrection === newDirection) return currentDirrection
        if (Math.abs(scrollY - prevScrollY) <= 10) return currentDirrection
        return newDirection
      })

      prevScrollYRef.current = Math.max(scrollY, 0)
    }, 1000),
  )

  useEffect(() => {
    const updateScrollDirection = updateScrollDirectionRef.current
    window.addEventListener('scroll', updateScrollDirection) // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection) // clean up
    }
  }, [])

  return scrollDirection
}

export default useScrollDirectionForWindow
