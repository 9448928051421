export const TwinkleMagnifyGlassIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <span data-nosnippet>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g style={{ mixBlendMode: 'difference' }}>
        <g clipPath="url(#clip0_880_242439)">
          <path
            d="M18.842 12.3741C18.842 16.9128 15.1628 20.592 10.6241 20.592C6.08552 20.592 2.40625 16.9128 2.40625 12.3741C2.40625 7.83552 6.08552 4.15625 10.6241 4.15625"
            stroke="currentColor"
            strokeWidth="1.27186"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.8943 22.6404L16.4258 18.1719"
            stroke="currentColor"
            strokeWidth="1.27186"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.6471 9.32812C19.9591 9.32812 20.2583 9.44665 20.4789 9.65763C20.6996 9.86861 20.8235 10.1548 20.8235 10.4531C20.8235 10.1548 20.9475 9.86861 21.1681 9.65763C21.3887 9.44665 21.688 9.32812 22 9.32812C21.688 9.32812 21.3887 9.2096 21.1681 8.99862C20.9475 8.78764 20.8235 8.50149 20.8235 8.20312C20.8235 8.50149 20.6996 8.78764 20.4789 8.99862C20.2583 9.2096 19.9591 9.32812 19.6471 9.32812ZM19.6471 2.57812C19.9591 2.57812 20.2583 2.69665 20.4789 2.90763C20.6996 3.11861 20.8235 3.40476 20.8235 3.70312C20.8235 3.40476 20.9475 3.11861 21.1681 2.90763C21.3887 2.69665 21.688 2.57812 22 2.57812C21.688 2.57812 21.3887 2.4596 21.1681 2.24862C20.9475 2.03764 20.8235 1.75149 20.8235 1.45312C20.8235 1.75149 20.6996 2.03764 20.4789 2.24862C20.2583 2.4596 19.9591 2.57812 19.6471 2.57812ZM15.5294 9.32812C15.5294 8.43302 15.9013 7.57457 16.5632 6.94164C17.225 6.3087 18.1228 5.95312 19.0588 5.95312C18.1228 5.95312 17.225 5.59755 16.5632 4.96461C15.9013 4.33168 15.5294 3.47323 15.5294 2.57812C15.5294 3.47323 15.1576 4.33168 14.4957 4.96461C13.8338 5.59755 12.9361 5.95312 12 5.95312C12.9361 5.95312 13.8338 6.3087 14.4957 6.94164C15.1576 7.57457 15.5294 8.43302 15.5294 9.32812Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_880_242439">
          <rect width="24" height="24" fill="white" transform="translate(0 0.453125)" />
        </clipPath>
      </defs>
    </svg>
  </span>
)
