import classNames from 'classnames'
import { MouseEvent, useCallback } from 'react'
import CloseIcon from 'shared/icons/CloseIcon'
import { getEllipsisText } from 'utils/string'

type RecentSearchItemProps = {
  title: string
  onRemove: () => void
  onSelect: (text: string) => void
}

const RecentSearchItem = (props: RecentSearchItemProps) => {
  const { title, onRemove, onSelect } = props

  const handleSelectRecentSearch = useCallback(() => {
    onSelect(title)
  }, [onSelect, title])

  const handleRemoveRecentSearch = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      onRemove()
    },
    [onRemove],
  )

  return (
    <span
      className={classNames(
        'inline-flex cursor-pointer items-center rounded-full border ',
        'px-[10px] py-2 text-xs lowercase hover:shadow-md',
        'whitespace-nowrap border-black/10 bg-[#F8FAFC] text-black ',
      )}
      onClick={handleSelectRecentSearch}
    >
      <button onClick={handleRemoveRecentSearch}>
        <CloseIcon />
      </button>
      <span className="px-1 lg:px-2">{getEllipsisText(title, 25)}</span>
    </span>
  )
}

export default RecentSearchItem
